import * as Sentry from '@sentry/vue';
import { cloneDeep } from 'lodash';
import Vue from 'vue';

import * as config from '@/../config/index.js';
import { routes } from '@/router/index.js';

import * as auth from './../../utils/auth.js';

const state = {
  user: {
    firstName: '',
    lastName: '',
    id: 0
  },
  userBalance: {
    amount: 0,
    currency: 'USD'
  },
  publisherUnpaidBalance: {
    amount: 0,
    currency: 'USD'
  },
  routes: cloneDeep(routes),
  role: localStorage.getItem('role') || null,
  emailVerified: null,
  availableRoles: [],
  notifications: [],
  invites: [],
  grants: [],
  pageError: null,
  pageLoading: false,
  sidebarExpanded: localStorage.getItem('sidebar')
    ? JSON.parse(localStorage.getItem('sidebar')).expanded
    : false,
  entryUrl: null,
  subIds: [],
  paymentMethods: [
    // { text: 'PayPal', value: 'paypal' },
    { text: 'Paxum', value: 'paxum' }
    // { text: 'Web Money', value: 'webmoney' },
    // { text: 'Wire transfer', value: 'wire' }
  ],
  paymentInfo: {},
  advertBalances: null,
  isBalanceLoading: false,
  messengers: [
    {
      id: 'telegram',
      name: 'Telegram'
    }
  ]
};

const mutations = {
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_BALANCE_LOADING(state, payload) {
    state.isBalanceLoading = payload;
  },
  SET_USER_BALANCE(state, payload) {
    state.userBalance = {
      amount: payload,
      currency: 'USD'
    };
  },
  SET_PUBLISHER_UNPAID_BALANCE(state, payload) {
    state.publisherUnpaidBalance = {
      amount: payload,
      currency: 'USD'
    };
  },
  SET_PAYMENT_INFO(state, payload) {
    state.paymentInfo = payload;
  },
  SET_INVITES(state, payload) {
    state.invites = payload;
  },
  SET_GRANTS(state, payload) {
    state.grants = payload;
  },
  SET_PAGE_FAILURE(state, payload) {
    if (!payload) {
      state.pageError = null;
    } else if (payload instanceof Error && payload.response) {
      state.pageError = {
        status: `${payload.response.status}`,
        statusText: payload.response.statusText
      };
    } else {
      const defaultError = this.$t
        ? this.$t('somethingWentWrong')
        : 'Something went wrong';
      state.pageError = {
        status: payload.status || 'Ooops...',
        statusText: payload.statusText || defaultError
      };
    }
  },
  MERGE_NOTIFICATIONS(state, payload) {
    state.notifications = [...state.notifications, ...payload];
  },
  SET_PAGE_LOADING(state, payload) {
    state.pageLoading = payload;
  },
  SET_USER_ROLE(state, payload) {
    state.role = payload;
  },
  SET_AVAILABLE_ROLES(state, roles) {
    if (Array.isArray(roles)) {
      state.availableRoles = roles
        .map((el) => {
          return config.default.roles.get(el);
        })
        .filter(Boolean);
    } else if (typeof roles === 'string' || roles instanceof String) {
      state.availableRoles = [roles.toLowerCase()];
    }
  },
  SET_EMAIL_VERIFIED(state, payload) {
    state.emailVerified = payload;
  },
  SET_SIDEBAR_EXPANDED(state, payload) {
    state.sidebarExpanded = payload;
    localStorage.setItem('sidebar', JSON.stringify({ expanded: payload }));
  },
  SET_ENTRY_URL(state, payload) {
    state.entryUrl = payload;
  },
  SET_ADVERT_BALANCES(state, payload) {
    state.advertBalances = payload;
  }
};

const getters = {
  routes: (state) => state.routes.filter((route) => !route.hidden),
  role: (state) => state.role,
  isBalanceLoading: (state) => state.isBalanceLoading,
  emailVerified: (state) => state.emailVerified,
  user: (state) => state.user,
  availableRoles: (state) => state.availableRoles,
  notifications: (state) => state.notifications,
  pageError: (state) => state.pageError,
  sidebarExpanded: (state) => state.sidebarExpanded,
  entryUrl: (state) => state.entryUrl,
  fullName: (state) => `${state.user.firstName} ${state.user.lastName}`,
  grants: (state) => state.grants,
  userGrants: (state) => {
    return state.grants.filter((el) => {
      return state.user.grants.some((uEl) => uEl === el.id);
    });
  },
  messengers: (state) => state.messengers
};

const actions = {
  logout: () => {
    Vue.api.logout();
  },
  fetchUser: async ({ commit }) => {
    const res = await Vue.api.auth
      .get('/profile')
      .then(async (res) => {
        if (res) {
          let user = cloneDeep(res);
          user.grants = res.grants || [];
          Sentry.setContext('user', { email: user.email });
          commit('SET_USER', user);
          commit('SET_AVAILABLE_ROLES', res.roles);
          commit('SET_EMAIL_VERIFIED', res.isEmailVerified);
          auth.saveUser(res);
        }
        return res;
      })
      .catch((e) => {
        commit('SET_PAGE_FAILURE', {
          status: 'Ooops...',
          statusText: "Couldn't get user data, please try to sign in again"
        });
        return Promise.reject(e);
      });

    if (state.availableRoles.includes('publisher')) {
      Vue.api.adnet
        .get(`/ssp/publishers/${state.user.id}/profile`)
        .then((res) => {
          commit('SET_USER', {
            ...state.user,
            pubProfile: res
          });
        });
    }
    return res;
  },
  fetchNotifications: ({ commit }, { limit = 10, cursor }) => {
    return Vue.api.auth
      .get('/v0/notifications', {
        params: { limit, cursor }
      })
      .then((res) => {
        commit('MERGE_NOTIFICATIONS', res.list);
        return res;
      });
  },
  fetchInvites({ commit, state }, data = {}) {
    if (state.invites.length > 0 && !data.refresh) return state.invites;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.auth.get(`/invites`, params).then((res) => {
      const response = res && res.list.length > 0 ? res.list : [];
      commit('SET_INVITES', response);
      return response;
    });
  },
  fetchGrants({ commit, state }, data = {}) {
    if (state.invites.length > 0 && !data.refresh) return state.grants;

    let baseUrl = Vue.api.auth.options.baseUrl;
    baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));

    const params = data.params
      ? { params: { ...data.params, baseURL: baseUrl } }
      : { baseURL: baseUrl };
    return Vue.api.auth.get(`/users/grants`, params).then((res) => {
      const response = res && res.length > 0 ? res : [];
      commit('SET_GRANTS', response);
      return response;
    });
  },
  updateRoles: ({ state }, { roles, id }) => {
    const userId = id ? id : state.user.id;
    return Vue.api.auth
      .put(`/users/roles/edit/${userId}`, {
        userId,
        roles
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  switchRole: ({ state, commit }, role) => {
    if (state.availableRoles.includes(role)) {
      commit('SET_USER_ROLE', role);
      auth.saveActiveRole(role);
      Sentry.setContext('role', { role: role });
    }

    if (
      typeof role === 'number' &&
      state.availableRoles.includes(config.default.roles.get(role))
    ) {
      const userRole = config.default.roles.get(role);
      commit('SET_USER_ROLE', userRole);
      auth.saveActiveRole(userRole);
      Sentry.setContext('role', { role: userRole });
    }
  },
  changeSidebarState: ({ commit }, expanded) => {
    commit('SET_SIDEBAR_EXPANDED', expanded);
  },
  fetchUserBalance: ({ commit }, userId) => {
    commit('SET_BALANCE_LOADING', true);
    return Vue.api.adnet
      .get(`/payment/transaction/${userId}/balance`)
      .then((res) => {
        commit('SET_USER_BALANCE', res.balance);
        commit('SET_BALANCE_LOADING', false);
      });
  },
  fetchPublisherUnpaidBalance: ({ commit }) => {
    commit('SET_BALANCE_LOADING', true);
    return Vue.api.adnet.get(`/payment/publisher/unpaid`).then((res) => {
      commit('SET_PUBLISHER_UNPAID_BALANCE', res.balance);
      commit('SET_BALANCE_LOADING', false);
    });
  },
  fetchPaymentInfo: ({ commit }) => {
    return Vue.api.adnet.get('/payment/information').then((res) => {
      commit('SET_PAYMENT_INFO', res);
      return res;
    });
  },
  savePaymentInfo: ({ commit }, payload) => {
    return Vue.api.adnet
      .put('/payment/information', payload)
      .then((res) => {
        commit('SET_PAYMENT_INFO', res);
        return res;
      })
      .catch((e) => Promise.reject(e));
  },
  fetchAdvertBalances({ commit, state }, data) {
    if (!data) return false;
    // if (state.advertBalances !== null && !data.refresh)
    //   return state.advertBalances;
    return Vue.api.adnet
      .post('/payment/advert/balances', { users: data.users })
      .then((res) => {
        commit('SET_ADVERT_BALANCES', res);
        return res;
      });
  }
};

export default {
  state,
  mutations,
  getters,
  actions
};
