<template>
  <nav class="clearfix mb-5">
    <div class="clearfix" :class="{ 'mt-5': isMobileLayout }">
      <router-link
        v-for="(item, index) in mainItems"
        :to="item.path"
        :title="item.meta && item.meta.title"
        :key="index"
        :class="{
          'd-none': hideDashboard && item.name === 'dashboard',
          'is-active': isActive(item.path)
        }"
      >
        <svg-icon :icon="item.meta && item.meta.icon" class="icon-menu" />
        <span>{{ item.meta && item.meta.title }}</span>
      </router-link>
      <collapse-nav-item
        v-for="(item, id) in collapsingItems"
        :items="items"
        :id="id"
        :title="item.title"
        :icon="item.icon"
        :key="id"
        :list="item.items"
        :main-nav-expanded="expanded"
      />
    </div>
  </nav>
</template>

<script>
import CollapseNavItem from './CollapseNavItem.vue';

export default {
  components: {
    CollapseNavItem
  },

  props: {
    items: {
      type: Array,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      search: {
        placeholder: 'Search for Advertising Campaign',
        roles: ['bounty-hunter', 'bounty-manager']
      },
      collapsingItems: {
        dsp: {
          title: 'DSP-Integrations',
          icon: 'hp-git-pull-request'
        },
        match: {
          title: 'Matching',
          icon: 'hp-matching',
          items: [
            {
              path: '/admin/matching/browser',
              meta: {
                title: 'Browsers',
                icon: 'hp-browser'
              }
            },
            {
              path: '/admin/matching/device',
              meta: {
                title: 'Devices',
                icon: 'hp-devices'
              }
            },
            {
              path: '/admin/matching/device-type',
              meta: {
                title: 'Device types',
                icon: 'hp-devices-types'
              }
            },
            {
              path: '/admin/matching/os',
              meta: {
                title: 'OS',
                icon: 'hp-os'
              }
            }
          ]
        }
      }
    };
  },

  computed: {
    mainItems() {
      // temporary to check desktop layout in mobile view
      // if (this.isMobileLayout) return this.items;
      const keys = Object.keys(this.collapsingItems);
      const items = this.items.filter((item) => {
        let result = true;
        for (let i = 0; i < keys.length; i++) {
          result = !item.name.includes(keys[i]);
          if (!result) break;
        }
        return result;
      });

      return items;
    },

    // temporary hack
    hideDashboard() {
      return ['advertiser'].includes(this.$store.getters.role);
    },

    isMobileLayout() {
      return (
        this.$screenState.md || this.$screenState.sm || this.$screenState.xs
      );
    }
  },

  methods: {
    isActive(url) {
      const isHomeLink = url === '/';
      const isHomePage = this.$route.fullPath === '/';

      if (!isHomeLink && !isHomePage) {
        return this.$route.fullPath.indexOf(url) !== -1;
      } else if (isHomePage && isHomeLink) return true;
    }
  }
};
</script>

<style lang="scss">
nav {
  --hp-menu-color: var(--bs-body-color);
  --hp-menu-secondary-bg: var(--bs-surface-secondary-bg);
  --hp-menu-color-active: var(--bs-primary);
  --hp-menu-color-hover: var(--bs-primary);
  --hp-menu-icon-color: var(--bs-icon-color);
  --hp-menu-icon-color-active: var(--bs-primary);
  --hp-menu-icon-color-hover: var(--bs-primary);
  position: relative;
  margin-top: 12px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    max-height: 400px;
    background: linear-gradient(
      to bottom,
      var(--bs-border-color) 0%,
      transparent 100%
    );
  }

  a {
    padding: 4px 0;
    padding-left: 16px;
    text-align: left;
    color: var(--hp-menu-color);
    font-family: 'Roboto', sans-serif;
    position: relative;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.938rem;
    transition: none;

    .icon-menu,
    .icon-menu-sm {
      color: var(--hp-menu-icon-color);
    }

    &:last-child {
      margin-bottom: 0;
    }
    span {
      color: inherit;
    }

    &.inner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: auto;
      margin-bottom: 0;
      padding-left: 20px;

      span {
        font-size: 0.9rem;
      }
    }

    i {
      width: fit-content;
      position: relative;
      bottom: -2px;
    }

    span {
      transform: translateX(-50px);
      opacity: 0;
      display: inline-block;
      position: relative;
      top: 2px;
      color: inherit;
    }

    &:hover {
      color: var(--hp-menu-color-hover);
      text-decoration: none;
    }

    &:after {
      content: ' ';
      height: 100%;
      width: 0;
      display: inline-block;
      vertical-align: middle;
    }

    &:before {
      content: ' ';
      height: 100%;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      left: 0;
      top: 0;
      width: 3px;
      background: var(--hp-menu-color-active);
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      transform: translateX(-3px);
      @extend %base-transition;
    }

    &.is-active {
      color: var(--hp-menu-color-active);
      @extend %base-transition-fast;
      .icon-menu {
        color: var(--hp-menu-color-active);
      }

      &:before {
        transform: translateX(0px);
      }

      &.inner {
        &:before {
          display: none;
        }
      }
    }
  }
}

.is-sidebar-expanded {
  a span {
    transform: translateX(0px);
    opacity: 1;
  }

  a.inner {
    padding: 2px 0 2px 24px;
  }
}

.in-mobile-menu {
  padding-top: 0;

  a span {
    transform: translateX(0px);
    opacity: 1;
  }
}

nav.in-mobile-menu {
  a {
    padding-left: 16px;
    white-space: normal;
    text-align: center;
    height: auto;

    &.is-active {
      &:before {
        display: none;
      }
    }

    span {
      white-space: normal;
    }
  }
}

.mobile-menu__search {
  padding: 0 20px;
  border-bottom: 1px solid var(--bs-border-color);
}
</style>
