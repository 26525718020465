<template>
  <div class="header-control" v-click-outside="hideHandler">
    <b-button
      variant="link"
      @click="toggleDropdown()"
      id="profile-dropdown-btn"
      class="me-0 profile__btn"
    >
      <div :class="{ 'is-mobile-control': isMobileLayout }">
        <svg-icon
          :icon="!isFakeLogin ? 'hp-user' : 'hp-eye'"
          class="icon-menu-md icon-color-normal"
          :class="{
            'text-success': isFakeLogin
          }"
        />
      </div>
    </b-button>
    <transition
      name="fade"
      @before-enter="$bus.$emit('headerDropdown:show')"
      @after-leave="$bus.$emit('headerDropdown:hide')"
    >
      <div
        v-if="isOpen"
        class="dropdown-menu"
        :class="['header-dropdown', { 'is-mobile-dropdown': isMobileLayout }]"
      >
        <div class="dropdown-menu-content">
          <div v-if="isFakeLogin" class="text-success text-center py-2">
            Impersonated login as
          </div>
          <div class="dropdown-menu-section">
            <div
              class="position-relative d-flex justify-content-start align-center px-3 py-3"
              v-if="user.email"
            >
              <div class="profile__avatar">
                <avatar :username="fullName" />
              </div>
              <div class="profile__info ps-3">
                <div class="dropdown-item-title mb-1">{{ fullName }}</div>
                <div class="dropdown-item-subtitle mb-1">{{ user.email }}</div>
                <div class="dropdown-item-description">
                  <small>{{ getRoleName(role) }}</small>
                </div>
              </div>
            </div>

            <template v-if="isAdmin">
              <hr class="dropdown-divider" />
              <ThemeSwitcher class="px-3 py-3 w-100" />
              <hr class="dropdown-divider" />
              <a
                class="dropdown-item dropdown-item-md"
                href="#"
                @click.prevent="openFakeLoginForm"
              >
                Login as ...
              </a>
            </template>

            <template v-if="filteredProfiles.length > 0 && user.email">
              <hr class="dropdown-divider" />
              <a
                v-for="item in filteredProfiles"
                class="dropdown-item dropdown-item-md"
                href="#"
                :key="item.email"
                @click.prevent="switchUser(item.email)"
              >
                {{ $t('switchTo') }} {{ item.email }}
              </a>
            </template>

            <template v-if="preparedAvailableRoles.length > 0">
              <hr class="dropdown-divider" />
              <a
                v-for="item in preparedAvailableRoles"
                class="dropdown-item dropdown-item-md"
                href="#"
                :key="item.value"
                @click.prevent="switchRole(item.value)"
              >
                {{ $t('switchTo') }} {{ getRoleName(item.value) }}
              </a>
            </template>

            <hr class="dropdown-divider" />
            <router-link
              to="/edit"
              class="dropdown-item dropdown-item-md"
              @click.native="hideHandler"
            >
              {{ $t('settings') }}
            </router-link>
            <router-link
              v-if="$can('visit', 'Payments')"
              to="/payments"
              class="dropdown-item dropdown-item-md"
              @click.native="hideHandler()"
            >
              {{ $t('payments') }}
            </router-link>
            <a
              href="#"
              class="dropdown-item dropdown-item-md"
              @click="signOut()"
              id="sign-out"
            >
              {{ $t('signOut') }}
            </a>
          </div>
        </div>
      </div>
    </transition>
    <fake-login-form v-if="isAdmin" ref="fake-login-form" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import * as config from '@/../config/index.js';
import Avatar from '@/components/Avatar.vue';
import FakeLoginForm from '@/components/FakeLoginForm.vue';
import * as auth from '@/utils/auth';
import ThemeSwitcher from '@/components/ThemeSwitcher/ThemeSwitcher.vue';

export default {
  components: {
    ThemeSwitcher,
    Avatar,
    FakeLoginForm
  },

  data() {
    return {
      isOpen: false
    };
  },

  computed: {
    ...mapState({
      userBalance: 'userBalance'
    }),
    ...mapGetters({
      availableRoles: 'availableRoles',
      role: 'role',
      user: 'user',
      fullName: 'fullName'
    }),
    isAdmin() {
      return ['advert-admin', 'manager'].includes(this.$store.getters.role);
    },

    isFakeLogin() {
      const fakeLogin = auth.readFakeLogin();
      return fakeLogin.user ? fakeLogin.user === this.user.email : false;
    },

    preparedAvailableRoles() {
      return (
        this.availableRoles &&
        this.availableRoles
          .filter((el) => {
            return el !== this.role && el !== false;
          })
          .map((role) => {
            return {
              value: role,
              title: role
                .replace('-', ' ')
                .replace(/\b[a-z](?=[a-z]{2})/g, (letter) =>
                  letter.toUpperCase()
                )
            };
          })
      );
    },
    isMobileLayout() {
      return (
        this.$screenState.md || this.$screenState.sm || this.$screenState.xs
      );
    },
    filteredProfiles() {
      const profiles = [];
      const fakeLogins = auth.readFakeLoginsList();
      Object.keys(this.profiles).forEach((key) => {
        if (this.user.email !== key && !fakeLogins.includes(key)) {
          profiles.push({
            email: key,
            token: this.profiles[key]
          });
        }
      });

      return profiles;
    },
    profiles() {
      let profiles = localStorage.getItem('jwt-tokens');
      return JSON.parse(profiles);
    },
    refreshTokens() {
      let tokens = localStorage.getItem('jwt-refresh-tokens');
      return JSON.parse(tokens);
    }
  },

  methods: {
    ...mapActions({
      fetchTargets: 'advert/fetchTargets'
    }),
    getRoleName(input) {
      if (!input) {
        return '';
      }
      let result = '';
      const words = input.split('-');
      words.forEach((word, index) => {
        if (index === 0) {
          result += word;
        } else {
          result += word.charAt(0).toUpperCase() + word.slice(1);
        }
      });

      return this.$t(result);
    },
    switchRole(role) {
      this.$bus.$emit('switch-role:start');
      this.hideHandler();
      this.$store.dispatch('switchRole', role).then(
        (res) => {
          this.$bus.$emit('switch-role:end');
          if (!this.$route.meta.roles) return res;
          this.$router.push({
            name: this.getRouteName()
          });
          this.fetchTargets({ refresh: true });
          return res;
        },
        (e) => e
      );
    },
    switchUser(email) {
      auth.saveUser({ email });
      this.$store.dispatch('fetchUser').then(
        (res) => {
          if (res) {
            this.switchRole(config.default.roles.get(res.roles[0]));
            this.$router.go();
          }
        },
        (e) => e
      );
      this.$api.updateCredentials({
        email: email,
        token: this.profiles[email],
        refreshToken: this.refreshTokens[email]
      });
    },

    async openFakeLoginForm() {
      this.$refs['fake-login-form'].openForm();
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    hideHandler() {
      if (!this.isOpen) return;
      this.isOpen = false;
    },
    signOut() {
      this.$store.dispatch('logout');
    },
    getRouteName() {
      switch (auth.readActiveRole()) {
        case 'admin':
          return 'users';
        case 'ad-exchange':
          return 'stats';
        case 'advertiser':
          return 'stats';
        case 'advert-admin':
        case 'manager':
        default:
          return 'dashboard';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/hprofits/popover.scss';

.profile {
  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    min-width: 0;
  }

  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    .vue-avatar {
      width: 4rem;
      height: 4rem;
      min-width: 4rem;
      min-height: 4rem;
    }
  }
}

.profile__btn {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bs-body-bg);
  border-radius: 50%;
  border: none;
  color: var(--bs-body-color);
  font-size: var(--bs-body-font-size-sm);
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    border-color: var(--bs-form-element-hover-border-color);
  }

  &:active,
  &:focus {
    border-color: var(--bs-form-element-focus-border-color);
    box-shadow: var(--bs-form-element-focus-box-shadow);
    text-decoration: none;
  }

  span {
    display: inline-block;
    padding-left: calc(var(--bs-spacer) * 0.625);
    position: relative;
    top: 1px;
  }

  &.is-mobile-control {
    .profile__username {
      display: none;
    }
  }
}

.header-control {
  display: inline-block;
  position: relative;

  .dropdown-menu {
    min-width: 320px;
    margin-top: calc(var(--bs-spacer) * 0.5);
    right: 0;
    left: auto;
    display: block;
    z-index: 1025;
    position: absolute;
    font-family: 'Onest', sans-serif;

    height: auto;

    &.is-mobile-dropdown {
      position: fixed;
      top: calc(var(--bs-spacer) * 4.6);
      left: 0;
      right: 0;
      padding: 0;
      margin: 0;
      height: auto;
      width: 100%;
      min-width: 100%;
      overflow-y: auto;
      border-radius: 0;
      border: none;
      border-bottom: var(--bs-border-width) solid var(--bs-border-color);

      .dropdown-menu-content {
        height: auto;
      }
    }

    .dropdown-menu-content {
      height: auto;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.profile-menu {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  .btn-link {
    color: var(--bs-body-color-muted);
    text-decoration: none;

    &:hover {
      color: var(--bs-primary);
      text-decoration: none;
    }
  }

  .profile__btn {
    svg {
      width: auto;
      height: auto;
      font-size: 1.5rem;
    }

    .icon-cross {
      svg {
        font-size: 1.5rem;
      }
    }
  }

  .in-mobile-menu {
    .profile__btn {
      svg {
        font-size: 2rem;
      }
    }
  }
}
</style>
