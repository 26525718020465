<template>
  <div class="ns__help">
    <p>
      The search has several modes of operation available.<br />
      To see the list of modes, type <kbd>:</kbd> when search focused.<br />
      To watch command list, type <kbd>/</kbd> when search focused.
    </p>

    <p>
      <b>Keyboard navigation</b>
    </p>

    <ul>
      <li><kbd>/</kbd> - open search</li>
      <li><kbd>up</kbd> / <kbd>down</kbd> - navigate search dropdown</li>
      <li>
        <kbd>enter</kbd> / <kbd>return</kbd> - go to link / choose search mode /
        use command
      </li>
      <li>
        <kbd>backspace</kbd> / <kbd>delete</kbd> - switch to the standard search
        mode when some mode is enabled
      </li>
      <li><kbd>esc</kbd> - close search drodown</li>
    </ul>
    <br />
    Search support several hotkeys:
    <ul>
      <li>
        <kbd>ctrl</kbd> / <kbd>control</kbd> + <kbd>c</kbd> - copy selected link
      </li>
      <li>
        <kbd>ctrl</kbd> / <kbd>control</kbd> + <kbd>enter</kbd> /
        <kbd>return</kbd> - open link in new tab
      </li>
    </ul>
    <b-alert model-value variant="info" class="mt-5">
      <b>Notice</b><br />
      When you use keyboard navigation, you don't have to select a specific item
      using <kbd>up</kbd> / <kbd>down</kbd>.<br />
      If no item is selected (not highlighted) - when you press
      <kbd>enter</kbd> / <kbd>return</kbd> - the action will be applied to the
      first item in the search result, if there is one.
    </b-alert>
  </div>
</template>

<style lang="scss">
.ns__help {
  font-size: 14px;

  kbd {
    background-color: var(--bs-surface-bg);
    color: var(--bs-body-color-medium);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
</style>
