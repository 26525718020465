<template>
  <header class="header">
    <div class="left" :class="{ 'is-collapsed': !sidebarExpanded }">
      <slot name="left" />
    </div>
    <div class="nav-search" :class="{ 'sidebar-collapsed': !sidebarExpanded }">
      <slot name="nav-search" />
    </div>
    <div class="actions-menu">
      <slot name="actions" />
    </div>
    <div class="profile-menu">
      <slot name="right" />
    </div>
  </header>
</template>

<script>
export default {
  props: {
    sidebarExpanded: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
$header-padding-x: 16px;
header {
  height: $header-height;
  padding: 12px $header-padding-x;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--bs-html-bg);
  border-bottom: 1px solid var(--bs-border-color);
  display: flex;
  align-items: center;
  @extend %base-transition;
  z-index: $zindex-fixed + 1;
}

.left {
  display: flex;
  height: 100%;
  width: calc($sidebar-width - $header-padding-x);
  @extend %base-transition;

  &.is-collapsed {
    width: calc($sidebar-width-collapsed - $header-padding-x);
  }
}

.nav-search {
  padding-left: 16px;
  max-width: 360px;
  width: 100%;
  @extend %base-transition;

  &.sidebar-collapsed {
    padding-left: 16px;
  }
}
</style>

<style lang="scss">
.actions-menu {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 4px;
  margin-right: 12px;
}
</style>
