const CodeTemplates = () => import('@/views/Admin/CodeTemplates/List.vue');
const CodeTemplatesForm = () => import('@/views/Admin/CodeTemplates/Form.vue');
const AdminSites = () => import('@/views/Admin/Sites/List.vue');
const AdminSyndicationDomains = () =>
  import('@/views/Admin/SyndicationDomains/List.vue');
const MatchingList = () => import('@/views/Admin/Matching/List.vue');
const MatchingForm = () => import('@/views/Admin/Matching/Form.vue');
const DBEntitiesForm = () => import('@/views/Admin/DBEntities/Form.vue');
const DSPFilters = () => import('@/views/Admin/DSPFilters/List.vue');
const DSPFiltersForm = () => import('@/views/Admin/DSPFilters/Form.vue');
const DSPFeed = () => import('@/views/Admin/DSPFeed/List.vue');
const DSPFeedForm = () => import('@/views/Admin/DSPFeed/Form.vue');
const AdminLandingsList = () => import('@/views/Admin/Landings/List.vue');
const Invites = () => import('@/views/Admin/Invites/List.vue');
const SendInvite = () => import('@/views/Admin/Invites/Send.vue');
const ExchangePoints = () => import('@/views/Admin/ExchangePoints/List.vue');
const ExchangePointsForm = () =>
  import('@/views/Admin/ExchangePoints/Form.vue');
const DSP = () => import('@/views/Admin/DSP/List.vue');
const DSPForm = () => import('@/views/Admin/DSP/Form.vue');
const SSP = () => import('@/views/Admin/SSP/List.vue');
const SSPForm = () => import('@/views/Admin/SSP/Form.vue');
const DSPFeedBindings = () => import('@/views/Admin/DSPFeedBindings/List.vue');
const DSPFeedBindingsForm = () =>
  import('@/views/Admin/DSPFeedBindings/Form.vue');
const SSPFeedForm = () => import('@/views/Admin/SSPFeed/Form.vue');
const DSPDirectFeedBindings = () =>
  import('@/views/Admin/DSPDirectFeedBindings/List.vue');
const DSPDirectFeedBindingsForm = () =>
  import('@/views/Admin/DSPDirectFeedBindings/Form.vue');
const SSPFeed = () => import('@/views/Admin/SSPFeed/List.vue');
const AutoRules = () => import('@/views/Admin/AutoRules/List.vue');
const AutoRuleForm = () => import('@/views/Admin/AutoRules/Form.vue');
const BlacklistAutoRules = () =>
  import('@/views/Admin/BlacklistAutoRules/List.vue');
const BlacklistAutoRuleForm = () =>
  import('@/views/Admin/BlacklistAutoRules/Form.vue');
const ModelRules = () => import('@/views/Admin/ModelRules/List.vue');
const ModelRuleForm = () => import('@/views/Admin/ModelRules/Form.vue');

const routes = [
  {
    path: '/admin/db-entities/add',
    component: DBEntitiesForm,
    name: 'add-db-entity',
    hidden: true,
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'addEntity',
      alias: 'dbe:a'
    }
  },
  {
    path: '/admin/db-entities/edit/:id',
    component: DBEntitiesForm,
    name: 'edit-db-entity',
    hidden: true,
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'editEntity'
    }
  },
  {
    path: '/code-templates',
    component: CodeTemplates,
    name: 'code-templates',
    meta: {
      roles: ['advert-admin', 'manager'],
      icon: 'hp-code',
      title: 'codeTemplates',
      alias: 'ctm'
    }
  },
  {
    path: '/code-templates/add',
    component: CodeTemplatesForm,
    name: 'add-code-templates',
    hidden: true,
    meta: {
      title: 'Add code template',
      roles: ['advert-admin', 'manager'],
      alias: 'ctm:a'
    }
  },
  {
    path: '/code-templates/edit/:id',
    component: CodeTemplatesForm,
    name: 'edit-code-templates',
    hidden: true,
    meta: {
      title: 'Edit code template',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/admin/filters',
    component: DSPFilters,
    name: 'dsp-filters',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'dspFilters',
      icon: 'hp-filter',
      alias: 'fl'
    }
  },
  {
    path: '/admin/filters/add',
    component: DSPFiltersForm,
    name: 'add-dsp-filter',
    hidden: true,
    meta: {
      title: 'Add filter',
      roles: ['advert-admin', 'manager'],
      alias: 'fl:a'
    }
  },
  {
    path: '/admin/filters/edit/:id',
    component: DSPFiltersForm,
    name: 'edit-dsp-filter',
    hidden: true,
    meta: {
      title: 'Edit filter',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/admin/feed',
    component: DSPFeed,
    name: 'dsp-feeds',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'dspFeed',
      icon: 'hp-dsp-feed',
      alias: 'fd'
    }
  },
  {
    path: '/admin/feed/add',
    component: DSPFeedForm,
    name: 'add-dsp-feeds',
    hidden: true,
    meta: {
      title: 'Add DSP feed',
      roles: ['advert-admin', 'manager'],
      alias: 'fd:a'
    }
  },
  {
    path: '/admin/feed/edit/:id',
    component: DSPFeedForm,
    name: 'edit-dsp-feeds',
    hidden: true,
    meta: {
      title: 'Edit DSP feed',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/admin/ssp-feed',
    component: SSPFeed,
    name: 'dsp-ssp-feed',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'sspFeed',
      icon: 'hp-ssp-feed',
      alias: 'sspf'
    }
  },
  {
    path: '/admin/ssp-feed/add',
    component: SSPFeedForm,
    name: 'add-ssp-feed',
    hidden: true,
    meta: {
      title: 'Add SSP feed',
      roles: ['advert-admin', 'manager'],
      alias: 'sspf:a'
    }
  },
  {
    path: '/admin/ssp-feed/edit/:id',
    component: SSPFeedForm,
    name: 'edit-ssp-feed',
    hidden: true,
    meta: {
      title: 'Edit SSP feed',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/admin/feed-bindings',
    component: DSPFeedBindings,
    name: 'dsp-feed-bindings',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'dspFeedBindings',
      icon: 'hp-dsp-bindings',
      alias: 'fb'
    }
  },
  {
    path: '/admin/feed-bindings/add',
    component: DSPFeedBindingsForm,
    name: 'add-dsp-feed-bindings',
    hidden: true,
    meta: {
      title: 'Add feed binding',
      roles: ['advert-admin', 'manager'],
      alias: 'fb:a'
    }
  },
  {
    path: '/admin/feed-bindings/edit/:id',
    component: DSPFeedBindingsForm,
    name: 'edit-dsp-feed-bindings',
    hidden: true,
    meta: {
      title: 'Edit feed binding',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/admin/direct-feed-bindings',
    component: DSPDirectFeedBindings,
    name: 'dsp-direct-feed-bindings',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'dspDirectFeedBindings',
      icon: 'hp-direct-bindings',
      alias: 'dfb'
    }
  },
  {
    path: '/admin/direct-feed-bindings/add',
    component: DSPDirectFeedBindingsForm,
    name: 'add-dsp-direct-feed-bindings',
    hidden: true,
    meta: {
      title: 'Add direct feed binding',
      roles: ['advert-admin', 'manager'],
      alias: 'dfb:a'
    }
  },
  {
    path: '/admin/direct-feed-bindings/edit/:id',
    component: DSPDirectFeedBindingsForm,
    name: 'edit-dsp-direct-feed-bindings',
    hidden: true,
    meta: {
      title: 'Edit direct feed binding',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/admin/ssp',
    component: SSP,
    name: 'dsp-integration-ssp',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'SSP',
      icon: 'hp-ssp',
      alias: 'ssp'
    }
  },
  {
    path: '/admin/ssp/add',
    component: SSPForm,
    name: 'add-ssp',
    hidden: true,
    meta: {
      title: 'Add SSP',
      roles: ['advert-admin', 'manager'],
      alias: 'ssp:a'
    }
  },
  {
    path: '/admin/ssp/edit/:id',
    component: SSPForm,
    name: 'edit-ssp',
    hidden: true,
    meta: {
      title: 'Edit SSP',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/admin/dsp',
    component: DSP,
    name: 'dsp',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'dsp',
      icon: 'hp-dsp',
      alias: 'dsp'
    }
  },
  {
    path: '/admin/dsp/add',
    component: DSPForm,
    name: 'add-dsp',
    hidden: true,
    meta: {
      title: 'Add DSP',
      roles: ['advert-admin', 'manager'],
      alias: 'dsp:a'
    }
  },
  {
    path: '/admin/dsp/edit/:id',
    component: DSPForm,
    name: 'edit-dsp',
    hidden: true,
    meta: {
      title: 'Edit DSP',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/admin/exchange-points',
    component: ExchangePoints,
    name: 'dsp-exchange-points',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'exchangePoints',
      icon: 'hp-exchange',
      alias: 'ep'
    }
  },
  {
    path: '/admin/exchange-points/add',
    component: ExchangePointsForm,
    name: 'add-dsp-exchange-points',
    hidden: true,
    meta: {
      title: 'Add exchange point',
      roles: ['advert-admin', 'manager'],
      alias: 'ep:a'
    }
  },
  {
    path: '/admin/exchange-points/edit/:id',
    component: ExchangePointsForm,
    name: 'edit-dsp-exchange-points',
    hidden: true,
    meta: {
      title: 'Edit exchange point',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/admin/landings',
    component: AdminLandingsList,
    name: 'admin-landing-groups',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'Landings',
      icon: 'hp-layout',
      alias: 'l'
    }
  },
  {
    path: '/admin/matching/:type',
    component: MatchingList,
    name: 'match-list',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'Match',
      icon: 'hp-matching'
    }
  },
  {
    path: '/admin/matching/:type/add',
    component: MatchingForm,
    name: 'add-match',
    hidden: true,
    meta: {
      title: 'Add match',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/admin/matching/:type/edit/:id',
    component: MatchingForm,
    name: 'edit-match',
    hidden: true,
    meta: {
      title: 'Edit match',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/admin/sites',
    component: AdminSites,
    name: 'sites-review',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'Sites',
      icon: 'hp-window',
      alias: 's'
    }
  },
  {
    path: '/admin/sydication-domains',
    component: AdminSyndicationDomains,
    name: 'syndication-domains-review',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'Syndication domains',
      icon: 'hp-link',
      alias: 'sd'
    }
  },
  {
    path: '/admin/invites',
    component: Invites,
    name: 'invites',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'Invites',
      icon: 'hp-mail',
      alias: 'inv'
    }
  },
  {
    path: '/admin/invites/send',
    component: SendInvite,
    name: 'send-invite',
    hidden: true,
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'Send invite',
      icon: 'hp-mail'
    }
  },
  {
    path: '/admin/auto-rules',
    component: AutoRules,
    name: 'auto-rules',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'Auto rules',
      icon: 'hp-cog',
      alias: 'ar'
    }
  },
  {
    path: '/admin/auto-rules/add',
    component: AutoRuleForm,
    name: 'add-auto-rule',
    hidden: true,
    meta: {
      title: 'Add Auto rule',
      roles: ['advert-admin', 'manager'],
      alias: 'ar:a'
    }
  },
  {
    path: '/admin/auto-rules/edit/:id',
    component: AutoRuleForm,
    name: 'edit-auto-rule',
    hidden: true,
    meta: {
      title: 'Edit Auto rule',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/admin/blacklist-auto-rules',
    component: BlacklistAutoRules,
    name: 'blacklist-auto-rules',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'Blacklist Auto rules',
      icon: 'hp-auto-rules',
      alias: 'bar'
    }
  },
  {
    path: '/admin/blacklist-auto-rules/add',
    component: BlacklistAutoRuleForm,
    name: 'add-blacklist-auto-rule',
    hidden: true,
    meta: {
      title: 'Add Blacklist Auto rule',
      roles: ['advert-admin', 'manager'],
      alias: 'bar:a'
    }
  },
  {
    path: '/admin/blacklist-auto-rules/edit/:id',
    component: BlacklistAutoRuleForm,
    name: 'edit-blacklist-auto-rule',
    hidden: true,
    meta: {
      title: 'Edit Blacklist Auto rule',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/admin/model-rules',
    component: ModelRules,
    name: 'model-rules',
    meta: {
      roles: ['advert-admin'],
      title: 'Model rules',
      icon: 'hp-auto-rules',
      alias: 'model'
    }
  },
  {
    path: '/admin/model-rules/add',
    component: ModelRuleForm,
    name: 'add-model-rule',
    hidden: true,
    meta: {
      title: 'Add Model rule',
      roles: ['advert-admin'],
      alias: 'model:a'
    }
  },
  {
    path: '/admin/model-rules/edit/:id',
    component: ModelRuleForm,
    name: 'edit-model-rule',
    hidden: true,
    meta: {
      title: 'Edit Model rule',
      roles: ['advert-admin']
    }
  }
];

export default routes;
