<template>
  <div class="select" :class="{ 'w-auto': autoWidth }">
    <button
      v-if="!button"
      type="button"
      class="btn-select"
      :class="[
        buttonClasses,
        {
          'is-disabled': disabled || (hideSelected && selectOptions.length <= 1)
        }
      ]"
      @click="toggleCheckboxes"
      :disabled="disabled || (hideSelected && selectOptions.length <= 1)"
    >
      <div class="button-label">
        <span
          class="selected-values"
          :class="{ 'is-excluded': exclude, 'd-flex': showOptions }"
          v-html="getBtnLabel"
        />

        <span class="caret">
          <Transition name="fade">
            <span
              @click.stop="clearSelected"
              v-if="!disabled && valueSelected.length > 0 && showClearBtn"
              class="clear-button-wrapper"
            >
              <svg-icon icon="hp-close" class="icon-lg clear-button" />
            </span>
          </Transition>
          <svg-icon
            v-if="!disabled"
            icon="hp-small-chevron-down"
            class="icon-1x chevron"
          />
        </span>
      </div>
    </button>
    <div
      v-if="
        !this.options.multi &&
        showSelectedDescription &&
        valueSelected.length &&
        valueSelected[0].description
      "
      class="selected-description text-muted mt-1"
    >
      {{ valueSelected[0].description }}
    </div>
    <slot v-else name="right-button" :click-handler="toggleCheckboxes" />
    <Transition name="fade">
      <div
        class="checkbox-layer"
        :class="{ show: isOpen, right: align === 'right' }"
        v-click-outside="externalClick"
        v-show="isOpen"
      >
        <div
          v-if="search || hasExcludeMode"
          class="helper-container helper-container--top"
        >
          <div class="line" style="position: relative">
            <svg-icon icon="hp-search" class="line__search icon-lg" />
            <input
              :placeholder="searchPlaceholder"
              type="text"
              v-model="searchInput"
              @input="searchfn()"
              autocomplete="nope"
              class="form-control"
              :class="{ 'no-right-border-radius': button }"
            />
          </div>
          <template v-if="hasExcludeMode">
            <button
              class="btn exclude-switcher"
              :class="{ 'is-active': exclude }"
              @click="excludeSwitch()"
            >
              {{ $t('excludeMode') }}: <span>{{ exclude ? 'ON' : 'OFF' }}</span>
            </button>
          </template>
        </div>
        <div class="check-box-container">
          <TransitionGroup name="list" tag="ul" class="select-list">
            <li
              v-for="option in sortedModelBySelected"
              :key="option.id"
              :data-option="option[idName]"
              class="select-item"
              :class="{
                disabled:
                  option.disabled ||
                  (maxLimit && maxLimit === count && !option.selected),
                selected: option.selected,
                hideSelected: option.selected && hideSelected,
                'd-none': !option.selected && showOnlySelected,
                'fw-bold': highlight(option)
              }"
              v-show="option.visible"
              @click="selectOption(option)"
            >
              <slot :option="option" :render-template="renderTemplate">
                <span
                  v-if="multi"
                  class="label-check"
                  :class="{ 'is-checked': option.selected }"
                ></span>
                <span class="label-name">
                  {{ renderTemplate(option) }}
                  <svg-icon
                    v-if="option.description"
                    icon="question-circle"
                    class="icon-xs description align-middle"
                    v-b-tooltip.hover="option.description"
                /></span>
              </slot>
            </li>
          </TransitionGroup>

          <p
            v-if="globalModel.length === 0"
            class="text-body-color-medium small px-2 py-2 text-center"
          >
            {{ $t('noData') }}
          </p>
        </div>
        <div
          v-if="multi || options.showOnlyClearBtn"
          class="helper-container bottom-controls"
        >
          <div class="line d-flex align-items-center justify-content-end gap-1">
            <div
              v-if="
                onlySelected &&
                valueSelected.length > 0 &&
                !options.showOnlyClearBtn
              "
              class="show-only-selected helper-button clear-btn"
              @click="toggleSelected"
            >
              <span
                class="label-check"
                :class="{ 'is-checked': showOnlySelected }"
              ></span>
              {{ $t('showOnlySelected') }}
            </div>
            <b-button
              type="button"
              size="small"
              variant="outline"
              @click="clearSelected()"
            >
              {{ $t('Clear') }}
            </b-button>
            <b-button
              v-if="!hideSubmitBtn && !options.showOnlyClearBtn"
              type="button"
              size="small"
              variant="primary"
              class="ms-auto"
              @click="isOpen = false"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash';

/* Вынужден предупредить что при включеной,
  ф-ии ексклюд мода, v-model не работает и стоит юзать
  @input евент в родительском компоненте */

export default {
  name: 'MultiSelect',
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    filters: {
      type: Array,
      default: () => []
    },
    selectOptions: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    showClearBtn: {
      type: Boolean,
      default: true
    },
    btnLabel: {
      type: String,
      default: 'Select'
    },
    btnClass: {
      type: String,
      default: ''
    },
    align: {
      type: String,
      default: 'left'
    },
    search: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...'
    },
    hasExcludeMode: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    zeroText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxLimit: {
      type: [Number, Boolean],
      default: false
    },
    hideSelected: {
      type: Boolean,
      default: false
    },
    showOptions: {
      type: Boolean,
      default: false
    },
    onlySelected: {
      type: Boolean,
      default: false
    },
    button: {
      type: Boolean,
      default: false
    },
    hideSubmitBtn: {
      type: Boolean,
      default: false
    },
    autoWidth: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: Function,
      default: () => {}
    },
    showSelectedDescription: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valueSelected: [],
      multiSelect: null,
      exclude: false,
      isOpen: false,
      globalModel: [],
      searchInput: '',
      optionsAllHide: false,
      showOnlySelected: false
    };
  },
  created() {
    this.setConfig();
  },
  methods: {
    setConfig() {
      this.multi = Boolean(this.options.multi) || false;
      this.labelName = this.options.labelName || 'name';
      this.idName = this.options.idName || 'id';
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.unshift({
        nameAll: 'Select all',
        nameNotAll: 'Deselect all',
        func: () => true
      });
      this.init();
    },
    init() {
      let clone = cloneDeep(this.selectOptions);

      if (typeof clone[0] === 'string' || typeof clone[0] === 'number') {
        clone = this.prepareArray(clone);
        this.simpleArray = true;
      }
      this.globalModel = clone;
      this.initValues();
    },
    initValues() {
      this.valueSelected = [];
      this.deselctAll();
      for (let j = 0; j < this.globalModel.length; j += 1) {
        this.globalModel[j].visible = true;

        if (typeof this.globalModel[j].selected !== 'boolean') {
          this.globalModel[j].selected = false;
        }
        for (let k = 0; k < this.modelValue.length; k += 1) {
          if (
            this.simpleArray &&
            this.globalModel[j][this.labelName] === this.modelValue[k]
          ) {
            this.globalModel[j].selected = true;
            this.valueSelected.push(this.globalModel[j][this.labelName]);
          } else if (
            !this.simpleArray &&
            this.globalModel[j][this.labelName] ===
              this.modelValue[k][this.labelName]
          ) {
            this.globalModel[j].selected = true;
            const opt = Object.assign({}, this.globalModel[j]);
            delete opt.selected;
            delete opt.visible;
            this.valueSelected.push(opt);
          }
        }
      }
      this.filter();
      !this.modelValue.length && this.emitInput();
    },
    toggleCheckboxes(event) {
      this.multiSelect = event.target;
      if (this.multiSelect.className === 'button-label') {
        this.multiSelect = this.multiSelect.parentNode;
      }
      this.isOpen = !this.isOpen;
    },
    externalClick(event) {
      if (this.isOpen) {
        let elem = event.target;
        if (!!elem && elem.className === 'button-label') {
          elem = elem.parentNode;
        }
        if (!!elem && elem.isSameNode(this.multiSelect)) {
          return;
        }
        this.isOpen = false;
      }
    },
    selectOption(option) {
      if (option.disabled) return;
      if (!option.selected) {
        if (!this.multi) {
          // eslint-disable-next-line vue/no-mutating-props
          this.filters[0].selectAll = true;
          this.deselctAll();
          this.valueSelected = [];
          this.emitInput();
          this.externalClick({ path: [] });
        }
        this.pushOption(option);
        this.emitInput();
      } else {
        this.popOption(option);
        this.emitInput();
      }
      option.selected = !option.selected;
      this.filter();
    },
    pushOption(option) {
      if (this.simpleArray) {
        this.valueSelected.push(option[this.labelName]);
      } else {
        const opt = Object.assign({}, option);
        delete opt.selected;
        delete opt.visible;
        this.valueSelected.push(opt);
      }
    },
    popOption(opt) {
      for (let i = 0; i < this.valueSelected.length; i += 1) {
        if (
          this.valueSelected[i][this.labelName] === opt[this.labelName] ||
          (this.simpleArray && this.valueSelected[i] === opt[this.labelName])
        ) {
          this.valueSelected.splice(i, 1);
          return;
        }
      }
    },
    searchfn() {
      let allHide = true;
      for (let i = 0; i < this.globalModel.length; i += 1) {
        if (
          ~this.globalModel[i][this.labelName]
            .toLowerCase()
            .indexOf(this.searchInput.toLowerCase())
        ) {
          allHide = false;
          this.globalModel[i].visible = true;
        } else {
          this.globalModel[i].visible = false;
        }
      }
      this.optionsAllHide = allHide;
      this.filter();
    },
    filter() {
      for (let i = 0; i < this.filters.length; i += 1) {
        let allSelected = true;
        for (let j = 0; j < this.globalModel.length; j += 1) {
          if (
            this.globalModel[j].visible &&
            this.filters[i].func(this.globalModel[j]) &&
            !this.globalModel[j].disabled &&
            !this.globalModel[j]['selected']
          ) {
            allSelected = false;
            break;
          }
        }
        // eslint-disable-next-line vue/no-mutating-props
        this.filters[i].selectAll = allSelected;
      }
    },
    deselctAll() {
      for (let i = 0; i < this.globalModel.length; i += 1) {
        if (!this.globalModel[i].disabled) {
          this.globalModel[i].selected = false;
        }
      }
    },
    prepareArray(value) {
      return value.map((elem) => ({ [this.labelName]: elem }));
    },
    clearSelected() {
      this.showOnlySelected = false;
      this.deselctAll();
      this.valueSelected = [];
      this.emitInput();
    },
    excludeSwitch() {
      this.exclude = !this.exclude;
      this.emitInput();
    },
    emitInput() {
      if (this.exclude) {
        const excluded = this.selectOptions.filter(
          (el) => !this.valueSelected.find((obj) => isEqual(obj, el))
        );
        this.$emit('update:modelValue', [...excluded]);
      } else {
        this.$emit('update:modelValue', [...this.valueSelected]);
      }
    },
    toggleSelected() {
      this.showOnlySelected = !this.showOnlySelected;
    }
  },
  computed: {
    sortedModelBySelected() {
      if (!this.multi) return this.globalModel;

      return [
        ...this.globalModel.filter((el) => el.selected),
        ...this.globalModel.filter((el) => !el.selected)
      ];
    },
    getBtnLabel() {
      if (!this.multi) {
        const label = this.valueSelected[this.valueSelected.length - 1];
        if (!label) return this.btnLabel;
        return `${
          label !== null && typeof label === 'object'
            ? label[this.labelName]
            : label
        }`;
      }
      let label = this.btnLabel;
      if (this.valueSelected.length > 0) {
        label = this.valueSelected.map((item) => item.name).join(', ');
      }
      const labelClass = `${
        this.showOptions ? 'w-100 overflow-hidden text-ellipsis pe-2 ' : ''
      }align-middle`;
      const countClass = `${
        this.showOptions ? 'ms-auto ' : ''
      }small align-middle`;
      if (this.multi && this.exclude) {
        return `<span class="${labelClass}">${label}</span>
          <i class="${countClass}">(${this.valueSelected.length} exclude)</i>`;
      }
      if (this.multi && !this.exclude) {
        return `<span class="${labelClass}">${label}</span>
          <i class="${countClass}">(${
            this.zeroText !== '' && this.valueSelected.length === 0
              ? this.zeroText
              : this.valueSelected.length
          })</i>`;
      }

      return this.btnLabel;
    },
    buttonClasses() {
      const propClasses = {};
      this.btnClass.split(' ').forEach((el) => {
        propClasses[el] = true;
      });

      return {
        'is-active': this.isOpen,
        'btn-select-lg': this.size === 'lg',
        'btn-select-md': this.size === 'md',
        'btn-select-sm': this.size === 'sm',
        'is-invalid': this.isInvalid,
        ...propClasses
      };
    },
    renderTemplate() {
      return this.options.renderTemplate || ((elem) => elem[this.labelName]);
    },
    count() {
      return this.modelValue.length;
    }
  },
  watch: {
    modelValue: {
      handler(newVal) {
        if (!isEqual(newVal, this.valueSelected)) {
          this.initValues();
        }
      },
      deep: true
    },
    selectOptions: {
      handler() {
        this.setConfig();
      },
      deep: true
    },
    'options.multi': function () {
      this.setConfig();
    },
    isOpen(val) {
      this.$emit(val ? 'opened' : 'closed');
    }
  }
};
</script>

<style lang="scss">
.clear-btn-wrapper {
  outline: 2px solid blue; /* Or any color matching your design */
  outline-offset: 2px;
}
.select {
  text-align: left;
  position: relative;
  width: 100%;
  line-height: 120%;

  & > .btn-select {
    min-height: 38px;
    height: 38px;
    border: var(--bs-form-element-border-width)
      var(--bs-form-element-border-style) var(--bs-border-color);
    border-radius: var(--bs-form-element-border-radius);
    color: var(--bs-body-color);
    font-size: var(--bs-form-element-font-size);
    padding: var(--bs-form-element-padding-y) var(--bs-form-element-padding-x);
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    text-align: left;
    user-select: none;
    white-space: normal;
    background-color: var(--bs-body-bg);
    transition: all 0.2s ease;
    outline: none;

    &.is-disabled {
      cursor: default;
      background-color: var(--bs-input-bg-disabled);
      border-color: var(--bs-input-border-color-disabled);
      color: var(--bs-input-color-disabled);
      opacity: 1;
    }

    &.is-invalid {
      border-color: var(--bs-form-invalid-border-color);
      &:focus {
        box-shadow: var(--bs-form-invalid-box-shadow);
      }
    }

    &:focus:not(.is-invalid) {
      border-color: var(--bs-form-element-focus-border-color);
      box-shadow: var(--bs-form-element-focus-box-shadow);
    }

    &:hover:not(:disabled):not(.is-invalid):not(:focus) {
      border-color: var(--bs-form-element-hover-border-color);
    }

    &.btn-select-lg {
      min-height: 44px;
      height: 44px;
      padding: var(--bs-form-element-padding-y)
        var(--bs-form-element-padding-x-lg);
      font-size: var(--bs-dropdown-font-size-lg);
      border-radius: var(--bs-form-element-border-radius-lg);
      line-height: 120%;
    }

    &.btn-select-sm {
      min-height: 34px;
      height: 34px;
      padding: var(--bs-form-element-padding-y-sm)
        var(--bs-form-element-padding-x-sm);
      padding-right: 30px;
      font-size: var(--bs-dropdown-font-size-sm);
      border-radius: var(--bs-form-element-border-radius-sm);
      line-height: 120%;

      &:has(.clear-button) {
        padding-right: 60px;
      }

      .button-label {
        font-size: var(--bs-dropdown-font-size-sm);
        line-height: 120%;
      }
    }
  }

  .button-label {
    word-break: break-word;
    display: inline-block;
    font-size: var(--bs-form-element-font-size);
    line-height: 120%;
    width: calc(100% - 2rem);
    text-align: left;
    padding: 0;
  }

  .chevron {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: var(--bs-btn-padding-x);
    color: var(--bs-body-color);
    margin-top: 1px;
    z-index: 1;

    i {
      width: 100%;
      height: 100%;
      transition: all 0.2s ease;
    }
  }

  .btn-select.is-active {
    border-color: var(--bs-form-element-focus-border-color);
    box-shadow: var(--bs-form-element-focus-box-shadow);
    z-index: 2;

    .chevron {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .checkbox-layer {
    background: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    box-shadow: var(--bs-box-shadow);
    min-width: var(--bs-dropdown-min-width);
    max-width: var(--bs-dropdown-max-width);
    overflow-y: auto;
    margin: 0;
    z-index: var(--z-index-popover);
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 10px;
    display: none;

    &.show {
      display: block;
    }

    &.right {
      right: 0;
      left: auto;
    }

    .helper-container {
      border-bottom: var(--bs-border-width) solid var(--bs-border-color);

      &--top {
        padding: 0;

        .line {
          position: relative;
          display: flex;
          align-items: center;

          .line__search {
            position: absolute;
            left: 0.75rem;
            color: var(--bs-gray-600);
            z-index: 1;
          }

          .form-control {
            padding-left: 2.25rem;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: none;
            min-height: var(--bs-form-element-height-lg);
          }
        }
      }

      &.bottom-controls {
        border-top: var(--bs-border-width) solid var(--bs-border-color);
        border-bottom: 0;
        padding: 0.5rem;

        .clear-btn {
          font-size: var(--bs-btn-font-size-sm);
          padding: 0.25rem 0.5rem;
          text-transform: capitalize;
          border: var(--bs-border-width) solid var(--bs-border-color);
          border-radius: var(--bs-border-radius);
          background: transparent;
          color: var(--bs-body-color);

          &:hover {
            border-color: var(--bs-primary);
            color: var(--bs-primary);
          }
        }
      }
    }

    .check-box-container {
      max-height: 300px;
      overflow-y: auto;
    }

    .select-list {
      margin: 0;
      padding: 0;
      list-style: none;

      .select-item {
        display: flex;
        align-items: center;
        width: 100%;
        border: 0;
        background: transparent;
        color: var(--bs-body-color);
        text-align: left;
        text-decoration: none;
        cursor: pointer;
        font-size: var(--bs-dropdown-menu-font-size);
        padding: var(--bs-dropdown-item-padding-y)
          var(--bs-dropdown-item-padding-x);
        min-height: var(--bs-dropdown-item-height-md);
        white-space: normal;
        padding-left: 16px;
        font-weight: 400;

        .label-check {
          display: inline-block;
          width: 1rem;
          height: 1rem;
          margin-right: 0.5rem;
          border: 1px solid var(--bs-gray-400);
          border-radius: 0.25rem;
          position: relative;
          flex-shrink: 0;
          transition: all 0.2s ease;

          &.is-checked {
            background-color: var(--bs-primary);
            border-color: var(--bs-primary);

            &:after {
              content: '';
              position: absolute;
              left: 5px;
              top: 2px;
              width: 4px;
              height: 8px;
              border: solid white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }
        }

        &:hover:not(:disabled):not(.disabled) {
          background: var(--bs-surface-secondary-bg);
          color: var(--bs-primary);
          text-decoration: none;

          .label-check:not(.is-checked) {
            border-color: var(--bs-primary);
          }
        }

        &:focus {
          outline: none;
          background: var(--bs-surface-secondary-bg);
        }

        &.active {
          background: var(--bs-primary);
          color: var(--bs-primary-color);

          .label-name,
          .description {
            color: var(--bs-primary-color);
          }
        }

        &.disabled {
          opacity: 0.6;
          cursor: not-allowed;
          pointer-events: none;
        }

        .label-name {
          flex-grow: 1;
          min-width: 0;
          margin-right: var(--bs-dropdown-icon-spacing);
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-size: inherit;
        }

        .description {
          color: var(--bs-body-color-medium);
          font-size: var(--bs-dropdown-font-size-sm);
          margin-left: auto;
        }
      }
    }
  }

  .empty-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bs-body-color-medium);
    font-size: var(--bs-dropdown-font-size-md);
  }
}
</style>
