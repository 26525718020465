<template>
  <button
    class="sidebar-collapse-btn"
    @click="$emit('sidebar-toggle')"
    :class="{ collapsed: !expanded }"
  >
    <svg-icon
      :style="!expanded && { transform: 'rotate(180deg)' }"
      icon="icon-menu-arrow"
      class="icon-1x"
    />
  </button>
</template>

<script>
export default {
  name: 'ButtonSidebarToggle',
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar-collapse-btn {
  color: var(--bs-icon-color);
  position: absolute;
  z-index: 100;
  width: 20px;
  height: 20px;
  right: 8px;
  border: 1px solid var(--bs-border-color);
  top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;

  &.collapsed {
    position: relative;
    right: -8px;
    width: 38px;
    height: 24px;
    border-radius: 8px;
  }

  transition: none;

  &:focus {
    outline: none;
  }

  &:hover {
    color: var(--bs-primary);
  }
}
</style>
